import React, { useLayoutEffect, useRef, useState } from "react";

const Sisalto = ({ rakenne, sisalto, asetaSisalto, otsikot, asetaOnkoMuokattu, seuraavaVapaaId, asetaSeuraavaVapaaId }) => {
    const [valittu, asetaValittu] = useState("-1");

    const ref = useRef();
    useLayoutEffect(() => (ref && ref.current && ref.current.focus()), [Object.keys(sisalto).length]);

    const uusiYksio = () => {
        sisalto[String(seuraavaVapaaId)] = new Array(otsikot.length).fill(0).map(() => [""]);
        asetaSisalto({ ...sisalto });

        asetaValittu(String(seuraavaVapaaId));
        asetaSeuraavaVapaaId(seuraavaVapaaId + 1);

        asetaOnkoMuokattu(true);
    };
    const poistaYksio = () => {
        asetaValittu("-1");
        delete sisalto[valittu];
        asetaSisalto({ ...sisalto });

        asetaOnkoMuokattu(true);
    };

    const uusiVaihtoehto = kentta => () => {
        sisalto[valittu][kentta].push("");
        asetaSisalto({ ...sisalto });
    };
    const poistaVaihtoehto = (kentta, i) => () => {
        sisalto[valittu][kentta].splice(i, 1);
        asetaSisalto({ ...sisalto });
    };
    const muokkaaVaihtoehtoa = (kentta, i) => e => {
        sisalto[valittu][kentta][i] = e.target.value;
        asetaSisalto({ ...sisalto });

        asetaOnkoMuokattu(true);
    };

    const hoidaNappaimenPainaminen = (kentta, i) => e => {
        if (e.altKey && e.key === "a") {
            e.preventDefault();
            uusiVaihtoehto(kentta)();
        }
        else if (e.altKey && e.key === "d") {
            e.preventDefault();
            poistaVaihtoehto(kentta, i)();
        }
    };

    const haeEdustaja = x => {
        if (rakenne.length === 0) return `{${x}}`;

        try {
            const sis = sisalto[x][rakenne[0]];
            return (sis && sis[0]) || "{tyhjä}";
        }
        catch {
            return "{virhe}";
        }
    };

    return <>
        <select size={6} value={valittu} onChange={e => {
            asetaValittu(e.target.value);
        }}>
            <option value={"-1"}>---</option>
            {Object.keys(sisalto).map(id => <option key={id} value={id}>{haeEdustaja(id)}</option>)}
        </select>
        {valittu !== "-1" && <button onClick={poistaYksio} title="Poista nykyinen yksiö">x</button>}
        {valittu !== "-1" && <>
            {rakenne.map((kentta, j) => <div key={kentta} style={{ marginBottom: 5 }}>
                {sisalto[valittu][kentta].map((sis, i) =>
                    <React.Fragment key={i}>
                        <input
                            ref={j === 0 && i === 0 ? ref : null}
                            placeholder={otsikot[kentta]} title={otsikot[kentta]}
                            autoCapitalize="off"
                            value={sis} onChange={muokkaaVaihtoehtoa(kentta, i)}
                            onKeyDown={hoidaNappaimenPainaminen(kentta, i)} />
                        <button onClick={poistaVaihtoehto(kentta, i)} tabIndex={-1} title="Poista tämä vaihtoehto">x</button>
                    </React.Fragment>)}
                <button onClick={uusiVaihtoehto(kentta)} tabIndex={-1} title="Uusi vaihtoehto">+</button>
            </div>)}
        </>}
        <div>
            <button onClick={uusiYksio} title="Luo uusi yksiö">Uusi yksiö</button>
        </div>
    </>;
};

export default Sisalto;