import React, { useState } from "react";

const Tuonti = ({ rakenne, otsikot, lisaa, tallennaKokoTietue, tietue }) => {
    const [tuonti, asetaTuonti] = useState("");
    const [tuontirakenne, asetaTuontirakenne] = useState([]);
    const [tuontimalli, asetaTuontimalli] = useState([]);

    const [erottimet, asetaErottimet] = useState({ rivi: "\n", kv: "\t", vaihtoehto: "," });

    // Jakaa tuonti-muuttujan sisällön kolmiulotteiseksi taulukoksi erottimet-muuttujine perusteella
    const jasentele = () => tuonti
        .split(erottimet.rivi)
        .map(t => t
            .split(erottimet.kv)
            .map(u => u
                .split(erottimet.vaihtoehto)
                .map(v => v.trim())));

    const valmisteleTuonti = () => {
        const jasennelty = jasentele();
        window.valmis = jasennelty;

        const pisinEnsimmainenOsa = jasennelty.reduce((summa, nyt) => Math.max(summa, nyt[0].length), 0);
        const pisinToinenOsa = jasennelty.reduce((summa, nyt) => Math.max(summa, nyt[1].length), 0);

        const malli = jasennelty.find(v => v[0].length === pisinEnsimmainenOsa && v[1].length === pisinToinenOsa);
        asetaTuontimalli(malli);
        asetaTuontirakenne([new Array(pisinEnsimmainenOsa).fill(-1), new Array(pisinToinenOsa).fill(-1)]);
    };

    const tuo = () => {
        const jasennelty = jasentele();
        const lisattavat = [];

        const f = (monesko, lisattava) => (eka, i) => {
            const mihin = tuontirakenne[monesko][i];
            if (mihin > -1)
                lisattava[mihin].push(eka);
        };

        jasennelty.forEach(sis => {
            const lisattava = new Array(otsikot.length).fill("").map(() => ([]));
            console.log(lisattava, sis);
            sis[0].forEach(f(0, lisattava));
            sis[1].forEach(f(1, lisattava));
            lisattavat.push(lisattava);
        });

        lisaa(lisattavat);
        console.log("Lisäsin nämä: ", lisattavat);
    };

    const muutaTuontirakennetta = (eka, toka) => e => {
        tuontirakenne[eka][toka] = e.target.value;
        console.log(tuontirakenne);
        asetaTuontirakenne([...tuontirakenne]);
    };


    const [tiedosto, asetaTiedosto] = useState(null);

    const tuoTiedostosta = e => {
        const fr = new FileReader();
        if (e.target.files && e.target.files.length <= 0) {
            asetaTiedosto(null);
            return;
        }
        fr.readAsText(e.target.files[0]);
        fr.onload = f => {
            console.log(f);
            console.log(f.target.result);
            try {
                const jy = JSON.parse(f.target.result);
                if (jy.nimi && jy.rakenne && jy.otsikot && jy.asetukset && jy.sisalto && jy.seuraavaVapaaId)
                    asetaTiedosto(jy);
                else throw new Error("Väärän muotoinen tiedosto");
            }
            catch (e) {
                asetaTiedosto({
                    virhe: "Tämä tiedosto ei sisällä sopivan muotoista tietuetta!"
                });
                console.log(e);
            }
        };
    };

    const vahvistaTuontiTiedostosta = () => {
        tallennaKokoTietue(tiedosto);
    };

    const [vienti, asetaVienti] = useState(false);
    const [latauslinkki, asetaLatauslinkki] = useState(null);
    const vie = () => {
        asetaVienti(true);

        asetaLatauslinkki(
            URL.createObjectURL(
                new Blob([JSON.stringify(tietue)], { type: "text/plain" })
            ));
    };

    const tuontimallimaaritys = osa => tuontimalli[osa].map((t, i) => <div key={i}>
        esim. {t}
        {<select value={tuontirakenne[osa][i]} onChange={muutaTuontirakennetta(osa, i)}>
            <option value={-1}>&lt;älä tuo tätä&gt;</option>
            {rakenne.map(r => <option key={r} value={r}>{otsikot[r]}</option>)}
        </select>}
    </div>);

    return <>
        <input onChange={tuoTiedostosta} type="file" />
        {tiedosto && tiedosto.virhe && <div>{tiedosto.virhe}</div>}
        {tiedosto && tiedosto.nimi && <>
            <div>Tuotava: {tiedosto.nimi} ({Object.keys(tiedosto.sisalto).length} yksiötä)</div>
            <button onClick={vahvistaTuontiTiedostosta}>Tuo tiedostosta</button>
        </>}
        <hr />
        <button onClick={vie}>Vie (lataa)</button>
        {vienti && <>
            <textarea value={JSON.stringify(tietue)} readOnly={true} />
            <p>Kopioi ylläoleva tai <a
                download="vie-oppinen.txt"
                href={latauslinkki}>lataa</a></p>
        </>}

        <hr />

        <input value={erottimet.rivi} onChange={e => asetaErottimet({ ...erottimet, rivi: e.target.value })} placeholder="rivierotin" />
        <input value={erottimet.kv} onChange={e => asetaErottimet({ ...erottimet, kv: e.target.value })} placeholder="kv-erotin" />
        <input value={erottimet.vaihtoehto} onChange={e => asetaErottimet({ ...erottimet, vaihtoehto: e.target.value })} placeholder="vaihtoehtoerotin" />
        <br />
        <textarea value={tuonti} onChange={e => asetaTuonti(e.target.value)} />
        <button onClick={valmisteleTuonti}>Yritä tuota</button>
        <p>----------</p>
        {tuontimalli.length > 0 && <>
            <div>Ensimmäinen osa</div>
            {tuontimallimaaritys(0)}
            <div>Toinen osa</div>
            {tuontimallimaaritys(1)}
            <button onClick={tuo}>Lisää nämä</button>
        </>}
    </>;
};

export default Tuonti;