const TIETOKANNAN_NIMI = "oppinen";
const TIETUEET_SAILIO = "tietueet";

let db;

export const avaaTietokanta = () => {
    return new Promise((resolve, reject) => {
        if (db) return resolve();

        const kys = indexedDB.open(TIETOKANNAN_NIMI, 1);
        kys.onerror = e => {
            console.log("[TK] Avatessani tietokantaa kävi virhe", e);
            reject(e);
        };
        kys.onsuccess = e => {
            console.log("[TK] Sain tietokannan auki.");
            resolve();
            db = e.target.result;
        };
        kys.onupgradeneeded = e => {
            db = e.target.result;
            db.createObjectStore(TIETUEET_SAILIO, { keyPath: "id", autoIncrement: true });
            console.log(`[TK] Loin tietokantaan säiliön '${TIETUEET_SAILIO}'.`);
        };
    });
};

const tyhjaTietue = () => ({
    nimi: "Uusi, tyhjä, vapaa",
    rakenne: [],
    otsikot: [],
    asetukset: [],
    sisalto: {},
    vari: ["#C0C0C0", "#BFFF00", "#808000", "#FFFF00", "#FFBF00", "#CC7722",
        "#00FFFF", "#007FFF", "#FFC0CB", "#FF00FF", "#FF2400"][Math.floor(Math.random() * 11)],
    seuraavaVapaaId: 0
});

export const tallennaTyhjaTietue = () => {
    return new Promise((resolve, reject) => {
        console.log(db);
        const uusi = tyhjaTietue();
        const req = db.transaction(TIETUEET_SAILIO, "readwrite").objectStore(TIETUEET_SAILIO).add(uusi);
        req.onsuccess = e => {
            console.log("[TK] Tallensin tyhjän tietueen onnistuneesti");
            resolve({
                ...uusi,
                id: e.target.result,
                koko: 0
            });
        };
        req.onerror = e => {
            console.log("[TK] Tallentaessani tyhjää tietuetta kohtasin virheen.", e);
            reject(e);
        };
    });

};

export const haeKaikkiTietueet = () => {
    return new Promise((resolve, _reject) => {
        const tietueet = [];
        db.transaction(TIETUEET_SAILIO).objectStore(TIETUEET_SAILIO).openCursor().onsuccess = e => {
            const osoitin = e.target.result;
            if (osoitin) {
                tietueet.push({
                    id: osoitin.key,
                    vari: osoitin.value.vari,
                    nimi: osoitin.value.nimi,
                    koko: Object.keys(osoitin.value.sisalto).length
                });
                osoitin.continue();
            }
            else resolve(tietueet);
        };
    });
};

export const haeTietue = id => {
    console.log(id);
    return new Promise((resolve, reject) => {
        const kys = db.transaction(TIETUEET_SAILIO).objectStore(TIETUEET_SAILIO).get(id);
        kys.onerror = e => {
            reject(e);
        };
        kys.onsuccess = e => {
            console.log(e);
            resolve(e.target.result);
        };
    });
};

export const tallennaTietue = tietue => {
    console.log(tietue);
    return new Promise((resolve, reject) => {
        const kys = db.transaction(TIETUEET_SAILIO, "readwrite").objectStore(TIETUEET_SAILIO).put(tietue);
        kys.onerror = e => {
            reject(e);
        };
        kys.onsuccess = e => {
            console.log(e);
            resolve(e.target.result);
        };
    });
};

export const poistaTietue = id => {
    return new Promise((resolve, reject) => {
        const kys = db.transaction(TIETUEET_SAILIO, "readwrite").objectStore(TIETUEET_SAILIO).delete(id);
        kys.onerror = e => {
            reject(e);
        };
        kys.onsuccess = () => {
            console.log("[TK] Poistin tietueen!");
            resolve(id);
        };
    });
};