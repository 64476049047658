import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Aloitus from "./Aloitus";
import Harjoittelu from "./harjoittelu/Harjoittelu";
import Kehys from "./Kehys";
import Muokkain from "./muokkain/Muokkain";
import "./tyyli.css";

const App = () => {
    return <Router>
        <Routes>
            <Route path="/" exact element={<Aloitus />} />
            <Route path="/harjoittele/:id" element={<Kehys lapsi={Harjoittelu} />} />
            <Route path="/muokkaa/:id" element={<Kehys lapsi={Muokkain} />} />
        </Routes>
    </Router>;
};

const root = createRoot(document.getElementById("main"));
root.render(<App />);

// Määritä SW
if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
        navigator.serviceWorker.register("/sw.js");
    });
}