import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { avaaTietokanta, haeKaikkiTietueet, tallennaTyhjaTietue } from "./tietokanta";

const Tietue = ({ id, nimi, koko, vari }) => {
    const siirry = useNavigate();

    return <div className="tietuekehys">
        <div className="tietuekuva" style={{ backgroundColor: vari || "lightgray" }} />
        <div className="tietuetietokehys">
            <div><Link className="linkki" to={`/muokkaa/${id}`}>{nimi}</Link></div>
            <div>{koko} {koko === 1 ? "yksiö" : "yksiötä"}</div>
            <div className="oikea-reuna">
                <button className="toimintopainike" title="Harjoittele" onClick={() => siirry(`/harjoittele/${id}`)}>🧠</button>
                <button className="toimintopainike" title="Tilastot" onClick={() => {}}>📈</button>
                <button className="toimintopainike" title="Muokkaa" onClick={() => siirry(`/muokkaa/${id}`)}>🖊️</button>
            </div>
        </div>
    </div>;
};

const Aloitus = () => {
    const [tk, asetaTk] = useState(0);

    const [tietueet, asetaTietueet] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                await avaaTietokanta();
                asetaTk(1); // Onnistui

                const tulos = await haeKaikkiTietueet();
                console.log(tulos);
                asetaTietueet(tulos);
            } catch (e) {
                console.log(e);
                asetaTk(2); // Virhe
            }
        })();
    }, []);

    const luoUusiTietue = () => {
        tallennaTyhjaTietue()
            .then(uusi => {
                asetaTietueet(tietueet.concat(uusi));
            })
            .catch(_virhe => {});
    };

    if (tk === 0)
        return <p>Ladataan...</p>;

    return <>
        {tk === 2 && <p>En saanut yhteyttä tietokantaan. (Sovellus ei toimi Firefoxin yksityisessä ikkunassa.)</p>}
        <div className="tietueluettelokehys">
            <h1>Tietueet ({tietueet.length})</h1>
            <button onClick={luoUusiTietue}>Luo uusi tyhjä tietue</button>
            {tietueet.map(tietue => <Tietue key={tietue.id}
                id={tietue.id} nimi={tietue.nimi} koko={tietue.koko} vari={tietue.vari} />)}
        </div>
    </>;
};

export default Aloitus;