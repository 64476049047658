import React from "react";

const Rakenne = ({ rakenne, asetaRakenne, otsikot, asetaOtsikot, sisalto, asetaSisalto, asetukset, asetaAsetukset, asetaOnkoMuokattu }) => {
    const poistaKentta = kohta => {
        asetaRakenne(rakenne.filter((_x, i) => i !== kohta));
    };

    const lisaaKentta = kohta => {
        rakenne.splice(kohta, 0, otsikot.length);
        asetaRakenne(rakenne);
        asetaOtsikot(otsikot.concat(""));
        asetaAsetukset(asetukset.concat(false));

        asetaSisalto(Object.fromEntries(
            Object.entries(sisalto)
                .map(([id, sis]) => [id, sis.concat([[""]])])
        ));

        asetaOnkoMuokattu(true);
    };

    return <>
        {rakenne.map((kentta, j) => <div key={kentta}>
            <div><button onClick={() => lisaaKentta(j)} title="Lisää uusi kenttä">+</button></div>
            <div>
                <input
                    value={otsikot[kentta]}
                    onChange={e => asetaOtsikot(otsikot.map((o, i) => kentta === i ? e.target.value : o))} />
                <label>
                    <input
                        type="checkbox"
                        checked={asetukset[kentta]}
                        onChange={e => asetaAsetukset(asetukset.map((a, i) => kentta === i ? e.target.checked : a))}
                    />
                    Älä kysy
                </label>
                <button onClick={() => poistaKentta(j)} title="Poista nykyinen kenttä">x</button>
            </div>
        </div>)}
        <button onClick={() => lisaaKentta(rakenne.length)} title="Lisää uusi kenttä">+</button>
    </>;
};

export default Rakenne;