import React, { useState } from "react";
import { Link } from "react-router-dom";
import Tuonti from "./Tuonti";
import { Valilehdet, Valilehti } from "../Valilehti";
import Sisalto from "./Sisalto";
import Rakenne from "./Rakenne";
import Poisto from "./Poisto";

const Muokkain = ({ tietue, tallenna }) => {
    const [nimi, asetaNimi] = useState(tietue.nimi);
    const [rakenne, asetaRakenne] = useState(tietue.rakenne);
    const [otsikot, asetaOtsikot] = useState(tietue.otsikot);
    const [asetukset, asetaAsetukset] = useState(tietue.asetukset);
    const [sisalto, asetaSisalto] = useState(tietue.sisalto);
    const [vari, asetaVari] = useState(tietue.vari || "black");

    const [onkoMuokattu, asetaOnkoMuokattu] = useState(false);

    const [seuraavaVapaaId, asetaSeuraavaVapaaId] = useState(tietue.seuraavaVapaaId);


    const lisaaUusiSisaltoJaData = lisattavat => {
        let id = seuraavaVapaaId;
        lisattavat.forEach(lisattava => {
            sisalto[String(id++)] = lisattava;
        });
        asetaSeuraavaVapaaId(id);
        asetaSisalto({ ...sisalto });

        asetaOnkoMuokattu(true);
    };

    const tallennaKokoTietue = t => {
        asetaNimi(t.nimi || nimi);
        asetaSisalto(t.sisalto || sisalto);
        asetaOtsikot(t.otsikot || otsikot);
        asetaVari(t.vari || vari);
        asetaAsetukset(t.asetukset || asetukset);
        asetaRakenne(t.rakenne || rakenne);
        asetaSeuraavaVapaaId(t.seuraavaVapaaId || seuraavaVapaaId);

        asetaOnkoMuokattu(true);
    };

    const koostaJaTallenna = () => {
        const uusiTietue = {
            id: tietue.id,
            nimi,
            rakenne,
            otsikot,
            asetukset,
            sisalto,
            vari,
            seuraavaVapaaId
        };
        console.log(uusiTietue);
        console.log("Koko noin", (JSON.stringify(uusiTietue).length / 1024).toFixed(2), "kt");
        tallenna(uusiTietue);
        asetaOnkoMuokattu(false);
    };

    return <>
        <Link to="/">Takaisin</Link>
        <button onClick={koostaJaTallenna}>Tallenna</button>
        {onkoMuokattu && <span>Sisältää tallentamattomia tietoja</span>}
        <hr />
        <input value={nimi} onChange={e => asetaNimi(e.target.value)} />
        <input value={vari} onChange={e => console.log(e.target.value) || asetaVari(e.target.value)} type="color" />
        <hr />

        <Valilehdet otsikot={["Rakenne", "Sisältö", "Tuo ja vie", "Poista"]}>
            <Valilehti>
                <Rakenne
                    rakenne={rakenne} asetaRakenne={asetaRakenne}
                    otsikot={otsikot} asetaOtsikot={asetaOtsikot}
                    sisalto={sisalto} asetaSisalto={asetaSisalto}
                    asetukset={asetukset} asetaAsetukset={asetaAsetukset}
                    asetaOnkoMuokattu={asetaOnkoMuokattu} />
            </Valilehti>
            <Valilehti>
                <Sisalto
                    rakenne={rakenne} otsikot={otsikot}
                    sisalto={sisalto} asetaSisalto={asetaSisalto}
                    asetaOnkoMuokattu={asetaOnkoMuokattu}
                    seuraavaVapaaId={seuraavaVapaaId} asetaSeuraavaVapaaId={asetaSeuraavaVapaaId} />
            </Valilehti>
            <Valilehti>
                <Tuonti
                    tietue={tietue} rakenne={rakenne} otsikot={otsikot}
                    lisaa={lisaaUusiSisaltoJaData} tallennaKokoTietue={tallennaKokoTietue} />
            </Valilehti>
            <Valilehti>
                <Poisto id={tietue.id} />
            </Valilehti>
        </Valilehdet>
    </>;
};

export default Muokkain;