import React, { useRef, useLayoutEffect } from "react";

const haeVinkki = (ehdotus, vastaukset) => {
    let katkaisu = ehdotus.length;
    let pisin = -1;
    let seuraava = '';

    for (let v = 0; v < vastaukset.length; v++) {
        const vastaus = vastaukset[v];
        const pituus = Math.min(vastaus.length, ehdotus.length + 1);

        for (let i = 0; i < pituus; i++)
            if (vastaus[i] !== ehdotus[i]) {
                if (i > pisin) {
                    katkaisu = i;
                    seuraava = vastaus[i];
                    pisin = i;
                }
                break;
            }
    }

    console.log(katkaisu, seuraava);

    return ehdotus.substring(0, katkaisu) + seuraava;
};

const Kentta = ({ otsikko, vastaukset, oletus, sisalto, muuta, asetaValmiiksi, vastattu, tarkenna, ilmoitaVirhe, vastaa }) => {
    const ref = useRef();
    useLayoutEffect(() => {
        if (tarkenna) ref.current.focus();
    }, [tarkenna]);

    const oikein = vastaukset.some(vas => vas.startsWith(sisalto));

    const muutaSisaltoa = uusi => {
        muuta(uusi);
        if (vastaukset.some(v => v === uusi))
            asetaValmiiksi();
    };

    const hoidaNappainAlhaalla = e => {
        if (e.key === "a" && e.altKey) {
            e.preventDefault();
            muutaSisaltoa(haeVinkki(sisalto, vastaukset));
            ilmoitaVirhe();
        }
    };

    if (oletus)
        return <p>{vastaukset.join(" / ")}</p>

    if (vastattu)
        return <div className="harjoitteluteksti">
            <div style={{ color: oikein ? "green" : "red" }}>{sisalto}</div>
            <div>{vastaukset.filter(v => v !== sisalto).join(" / ")}</div>
        </div>;

    return <div>
        <input
            ref={ref}
            className="harjoittelukentta"
            value={sisalto}
            placeholder={otsikko}
            style={{ color: oikein ? "green" : "red" }}
            autoCapitalize="off"
            title={otsikko}
            onChange={e => muutaSisaltoa(e.target.value)}
            onKeyDown={hoidaNappainAlhaalla}
        />
    </div>;
};

export default Kentta;