import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { poistaTietue } from "../tietokanta";

const Poisto = ({ id }) => {
    const [onkoVarma, asetaOnkoVarma] = useState(false);

    const siirry = useNavigate();

    const hoidaPoisto = () => {
        poistaTietue(id)
            .then(() => {
                siirry("/");
            })
            .catch(e => {
                console.log(e);
            });
    };

    return <div>
        <label>
            <input type="checkbox" checked={onkoVarma} onChange={e => asetaOnkoVarma(e.target.checked)} />
            Haluan varmasti poistaa tämän tietueen.
        </label>
        {onkoVarma && <button onClick={hoidaPoisto}>Poista tietue</button>}
    </div>
};

export default Poisto;