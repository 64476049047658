import React, { useState } from "react";

const otsikko = {
    color: "white",
    backgroundColor: "black",
    lineHeight: "50px",
    padding: "0px 10px",
    marginBottom: "-1px",
    cursor: "pointer"
};

const otsikkokehys = {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "black"
};

const valittuOtsikko = {
    color: "black",
    backgroundColor: "white",
    lineHeight: "50px",
    padding: "0px 10",
    borderTop: "2px solid black",
    borderLeft: "2px solid black",
    borderRight: "2px solid black",
    marginBottom: "-1px",
    boxShadow: "0px 0px 10px white",
    zIndex: 999,
    cursor: "pointer"
};
const valilehtikehys = {
    border: "1px solid black",
    padding: 10
};

export const Valilehti = ({ children }) => {
    return <div>{children}</div>;
};

export const Valilehdet = ({ otsikot, children }) => {
    const [selectedTab, setSelectedTab] = useState(0);

    return (
        <div>
            <div style={otsikkokehys}>
                {otsikot.map((title, i) => <div key={i}
                    title={title} tabIndex={0}
                    style={i === selectedTab ? valittuOtsikko : otsikko}
                    onClick={() => setSelectedTab(i)}
                    onKeyDown={e => e.key === "Enter" && setSelectedTab(i)}>
                    {title}
                </div>)}
            </div>
            <div style={valilehtikehys}>{children[selectedTab]}</div>
        </div>
    );
};