import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { Link } from "react-router-dom";
import Kentta from "./Kentta";
import Saadot from "./Saadot";

// https://bost.ocks.org/mike/shuffle/
const sekoita = taulukko => {
    let m = taulukko.length, t, i;
    while (m) {
        i = Math.floor(Math.random() * m--);
        t = taulukko[m];
        taulukko[m] = taulukko[i];
        taulukko[i] = t;
    }
    return taulukko;
}

const Harjoittelu = ({ tietue, yksioidenMaara }) => {
    const [asetukset, asetaAsetukset] = useState(tietue.asetukset);

    const [syote, asetaSyote] = useState(new Array(tietue.otsikot.length).fill(""));
    const [vastattu, asetaVastattu] = useState(tietue.asetukset);
    const [kohta, asetaKohta] = useState(0);
    const [oikein, asetaOikein] = useState(0);
    const [onkoVirhe, asetaOnkoVirhe] = useState(false);

    const [kysyttavat, asetaKysyttavat] = useState([]);
    const [vaarinMenneet, asetaVaarinMenneet] = useState([]);

    const [edistys, asetaEdistys] = useState([]);
    const [lapaisty, asetaLapaisty] = useState(0);

    const LS_NIMI = `oppinen-tila-${tietue.id}`;

    useEffect(() => {
        const data = localStorage.getItem(LS_NIMI);
        if (data === null)
            asetaKysyttavat(sekoita(Object.keys(tietue.sisalto)));
        else {
            const rakenne = JSON.parse(data);
            console.log(rakenne);
            asetaKysyttavat(rakenne.kysyttavat);
            asetaEdistys(rakenne.edistys);
            asetaKohta(rakenne.kohta);
            asetaVaarinMenneet(rakenne.vaarinMenneet);
            asetaLapaisty(rakenne.lapaisty);
        }
    }, []);

    const vastaamattomat = tietue.rakenne.map(v => [vastattu[v], v]).filter(v => v[0] === false);
    const vastaamaton = !!vastaamattomat.length && vastaamattomat[0].length === 2 && vastaamattomat[0][1];

    const ref = useRef();
    useLayoutEffect(() => {
        if (ref.current)
            ref.current.focus();
    }, [oikein]);

    const hoidaSeuraavaanSiirtyminen = () => {
        const tallennettava = {
            kysyttavat,
            kohta: kohta + 1,
            edistys: edistys.concat(onkoVirhe),
            lapaisty,
            vaarinMenneet
        };

        if (!onkoVirhe) {
            asetaLapaisty(lapaisty + 1);
            tallennettava.lapaisty++;
        }
        else {
            asetaVaarinMenneet(vaarinMenneet.concat(kysyttavat[kohta]));
            tallennettava.vaarinMenneet = vaarinMenneet.concat(kysyttavat[kohta])
        }

        try {
            console.time("tilantallennusaika");
            localStorage.setItem(LS_NIMI, JSON.stringify(tallennettava));
            console.timeEnd("tilantallennusaika");
        }
        catch (e) {
            console.log(e.message);
        }

        if (tallennettava.kohta === kysyttavat.length && tallennettava.lapaisty >= yksioidenMaara)
            localStorage.removeItem(LS_NIMI);

        asetaEdistys(edistys.concat(onkoVirhe));
        asetaKohta(kohta + 1);
        asetaSyote(new Array(tietue.otsikot.length).fill(""));
        asetaVastattu(asetukset);
        asetaOikein(0);
        asetaOnkoVirhe(false);
    };

    const hoidaKierroksenVaihto = () => {
        // edistys.map((virhe, i) => [virhe, kysyttavat[i]]).filter(([virhe]) => virhe).map(p => p[1]);

        asetaKysyttavat(vaarinMenneet);
        asetaVaarinMenneet([]);
        asetaEdistys([]);

        asetaKohta(0);
        asetaOnkoVirhe(false);
        asetaSyote(new Array(tietue.otsikot.length).fill(""));
        asetaOikein(0);
    };

    const vastaa = () => {
        asetaOnkoVirhe(true);
        asetaOikein(oikein + 1);
        asetaVastattu(asetukset.map(() => true));
    };

    const aloitaAlusta = () => {
        localStorage.removeItem(LS_NIMI);
        asetaKysyttavat(sekoita(Object.keys(tietue.sisalto)));
        asetaKohta(0);
        asetaVaarinMenneet([]);
        asetaEdistys([]);
        asetaLapaisty(0);
        asetaOikein(0);
        asetaSyote(new Array(tietue.otsikot.length).fill(""));
        asetaVastattu(asetukset);
        asetaOnkoVirhe(false);
    };

    const edistyminen = () => <div style={{ display: "flex" }}>
        {edistys.map((virhe, i) => <div key={i} style={{
            backgroundColor: virhe ? "#e74c3c" : "#07bc0c",
            height: 5,
            width: `${100 / kysyttavat.length}%`,
            flexShrink: 0
        }} />)}
    </div>;

    const ylapalkki = () => <>
        {edistyminen()}
        <Saadot
            rakenne={tietue.rakenne} otsikot={tietue.otsikot}
            asetukset={asetukset} asetaAsetukset={asetaAsetukset}
            asetaVastattu={asetaVastattu} aloitaAlusta={aloitaAlusta} />
    </>;

    if (kohta === kysyttavat.length && lapaisty < yksioidenMaara)
        return <>
            {ylapalkki()}
            <div className="harjoittelukehys tasa-asettelu">
                <div className="koko-leveys">Vastasit kaikkiin!</div>
                <div className="puoliksi">
                    <div>Oikein</div>
                    <div className="suursisalto" style={{
                        color: "#07bc0c"
                    }}>{lapaisty}</div>
                </div>
                <div className="puoliksi">
                    <div>Vielä opeteltava</div>
                    <div className="suursisalto" style={{
                        color: "#e74c3c"
                    }}>{yksioidenMaara - lapaisty}</div>
                </div>
                <div className="harjoittelupainikekehys">
                    <button ref={ref}
                        className="harjoittelupainike"
                        onClick={hoidaKierroksenVaihto}>Harjoittele väärin menneitä</button>
                </div>
            </div>
        </>;

    if (kohta === kysyttavat.length && lapaisty >= yksioidenMaara)
        return <>
            {ylapalkki()}
            <div className="harjoittelukehys tasa-asettelu">
                <div className="koko-leveys">Sait kaikki oikein!</div>
            </div>
        </>;

    return <>
        {ylapalkki()}
        {onkoVirhe && <p>VIRHE!!!</p>}
        <div className="harjoittelukehys">
            {tietue.rakenne.map(kentta =>
                <Kentta
                    key={kentta}
                    tarkenna={kentta === vastaamaton}
                    otsikko={tietue.otsikot[kentta]}
                    vastaukset={tietue.sisalto[kysyttavat[kohta]][kentta]}
                    oletus={asetukset[kentta]}
                    sisalto={syote[kentta]}
                    vastattu={vastattu[kentta]}
                    vastaa={vastaa}
                    muuta={arvo => asetaSyote(syote.map((s, i) => kentta === i ? arvo : s))}
                    asetaValmiiksi={() => {
                        asetaOikein(oikein + 1);
                        asetaVastattu(vastattu.map((t, i) => i === kentta ? !t : t));
                    }}
                    ilmoitaVirhe={() => asetaOnkoVirhe(true)}
                />)}

            <div className="harjoittelupainikekehys">
                {vastaamaton === false
                    ? <button ref={ref} className="harjoittelupainike" onClick={hoidaSeuraavaanSiirtyminen}>Seuraava</button>
                    : <button className="harjoittelupainike" onClick={vastaa}>Vastaa</button>}
            </div>
        </div>
    </>;
};

export default Harjoittelu;