import React, { useState } from "react";

const Saadot = ({ rakenne, otsikot, asetukset, asetaAsetukset, asetaVastattu, aloitaAlusta }) => {
    const [onkoAuki, asetaOnkoAuki] = useState(false);

    return <div>
        <button className="toimintopainike" onClick={() => asetaOnkoAuki(!onkoAuki)}>⚙️</button>
        {onkoAuki && <div>
            <div>
                <button onClick={aloitaAlusta}>Aloita alusta</button>
            </div>
            <div>
                {rakenne.map(kentta => <div key={kentta}>
                    <label>
                        <input type="checkbox" checked={asetukset[kentta]}
                            onChange={e => {
                                const muokattu = asetukset.map((v, j) => j === kentta ? e.target.checked : v);
                                asetaAsetukset(muokattu);
                                asetaVastattu(muokattu);
                            }} />
                        Näytä '{otsikot[kentta]}'
                    </label>
                </div>)}
            </div>
        </div>}
    </div>;
};

export default Saadot;