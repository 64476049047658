import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { avaaTietokanta, haeTietue, tallennaTietue } from "./tietokanta";

const Kehys = ({ lapsi }) => {
    const { id } = useParams();

    const [tietue, asetaTietue] = useState({});

    console.log(id);

    useEffect(() => {
        (async () => {
            try {
                await avaaTietokanta();

                const tulos = await haeTietue(Number(id));
                console.log(tulos);
                asetaTietue(tulos);
            }
            catch (e) {
                console.log(e);
            }
        })();
    }, []);

    const tallenna = async t => {
        try {
            await tallennaTietue(t);
            console.log("Tallennettu!");
        }
        catch (e) {
            console.log(e);
        }
    };

    if (!tietue || !tietue.sisalto)
        return <p>Ladataan...</p>;

    return React.createElement(lapsi, { tietue, yksioidenMaara: Object.keys(tietue.sisalto).length, tallenna });
};

export default Kehys;